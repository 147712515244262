import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "3vw",
    paddingRight: "3vw",
    paddingTop: "1.8vw",
    paddingBottom: "1.8vw",
  },
  logo: {
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0em",
    textTransform: "none",
    cursor: "pointer",
    fontSize: "3.2rem",
    lineHeight: "1.2rem",
  },
  grid: {
    paddingTop: "1vw",
    paddingBottom: "1vw",
  },
  appBar: {
    boxShadow: "none",
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} color="secondary" id="appBar">
      <Toolbar className={classes.root}>
        <Grid
          alignItems="center"
          className={classes.grid}
          container
          justify="space-between"
          spacing={8}
        >
          <Grid item>
            <Typography className={classes.logo} color="primary">
              Alums for Black Lives
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
