import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { RadioGroup, TextField, Select } from "formik-material-ui";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";
import { Alert, createFilterOptions } from "@material-ui/lab";
import StripeForm from "./StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Autocomplete } from "formik-material-ui-lab";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51HZgqPHBMdH9P4lAOgsLkUgwndGzu1XBOFgio2abSBm9fiQlztW4sX8ysAXWsDuEQJZ30uoLVLInK5WF80kPdqCF00qkDH6nRh"
);

const baseURL = "https://ff0xs2s946.execute-api.us-east-1.amazonaws.com/";

const CCForm = () => {
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Something went wrong");
  const [viewState, setViewState] = useState("CUSTOMER");
  const [paymentInfo, setPaymentInfo] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const loadOptionsForOrganizations = async () => {
    try {
      const results = await fetch(baseURL + "organizations");
      const parsedResults = await results.json();
      setOrganizations(parsedResults);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadOptionsForOrganizations();
    return () => {};
  }, []);

  const createStripeCustomer = async values => {
    console.log(values);
    try {
      const results = await fetch(
        "https://ff0xs2s946.execute-api.us-east-1.amazonaws.com/create-customer",
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const parsedResults = await results.json();
      console.log("The results are: ", parsedResults);
      let amount = values.amount;
      if (values.amount === "X") {
        amount = values.variableAmount;
      }
      setPaymentInfo({
        customerId: parsedResults.id,
        schedule: values.schedule,
        amount,
      });
      setViewState("CARD");
    } catch (error) {
      setAlertMessage(error.message);
      setAlertVisible(true);
    }
  };

  const addPaymentMethod = async paymentMethod => {
    console.log("payment method: ", paymentMethod);
    setViewState("LOADING");
    try {
      const results = await fetch(
        "https://ff0xs2s946.execute-api.us-east-1.amazonaws.com/process-payment",
        {
          method: "POST",
          body: JSON.stringify({
            ...paymentInfo,
            paymentMethod: paymentMethod.id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const parsedResults = await results.json();
      console.log("payment results: ", parsedResults);
      setViewState("THANKS");
    } catch (error) {
      setViewState("CARD");
      setAlertMessage(error.message);
      setAlertVisible(true);
    }
  };
  const filter = createFilterOptions();

  return (
    <>
      <Card>
        <CardHeader title="Donate to GSB" />
        <Divider />
        <CardContent>
          {viewState === "LOADING" && (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          )}
          {isAlertVisible && (
            <Box mb={3}>
              <Alert onClose={() => setAlertVisible(false)} severity="info">
                {alertMessage}
              </Alert>
            </Box>
          )}
          {viewState === "CUSTOMER" && (
            <Formik
              initialValues={{
                submit: null,
                amount: "20",
                schedule: "quarterly",
                firstName: "",
                lastName: "",
                email: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zip: "",
                organization: null,
              }}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  // NOTE: Make API request
                  console.log("submitting form");
                  await createStripeCustomer(values);
                  // resetForm()
                  setStatus({ success: true });
                  setSubmitting(false);
                } catch (err) {
                  console.log("failed to submit form");
                  console.log(err);
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required("Required"),
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                amount: Yup.string().required("Required"),
                schedule: Yup.string().required("Required"),
                addressLine1: Yup.string().required("Required"),
                addressLine2: Yup.string().optional(),
                city: Yup.string().required("Required"),
                state: Yup.string().length(2).required("Required"),
                zip: Yup.string().length(5).required("Required"),
                variableAmount: Yup.string().optional(),
                organization: Yup.string().required("Required"),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <>
                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={5}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Amount</FormLabel>
                            <Field
                              aria-label="amount"
                              component={RadioGroup}
                              name="amount"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                color="primary"
                                control={<Radio color="primary" />}
                                label="$5"
                                value="5"
                              />
                              <FormControlLabel
                                control={<Radio color="primary" />}
                                label="$10"
                                value="10"
                              />
                              <FormControlLabel
                                color="primary"
                                control={<Radio color="primary" />}
                                label="$20"
                                value="20"
                              />
                              <FormControlLabel
                                color="primary"
                                control={<Radio color="primary" />}
                                label="$50"
                                value="50"
                              />
                              <FormControlLabel
                                color="primary"
                                control={<Radio color="primary" />}
                                label="$100"
                                value="100"
                              />
                              <Grid container>
                                <Grid item>
                                  <FormControlLabel
                                    color="primary"
                                    control={<Radio color="primary" />}
                                    label="$"
                                    value="X"
                                  />
                                </Grid>
                                <Grid item>
                                  <Field
                                    component={TextField}
                                    error={Boolean(
                                      touched.variableAmount &&
                                        errors.variableAmount
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.variableAmount &&
                                      errors.variableAmount
                                    }
                                    name="variableAmount"
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel htmlFor="donation-schedule">
                            Donation Schedule
                          </InputLabel>
                          <Field
                            component={Select}
                            inputProps={{
                              id: "donation-schedule",
                            }}
                            name="schedule"
                            variant="outlined"
                          >
                            <MenuItem value="once">One Time</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="quarterly">Quarterly</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={Autocomplete}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              if (params.inputValue !== "") {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  title: `Add "${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            getOptionLabel={option => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.title;
                            }}
                            name="organization"
                            options={organizations}
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                error={
                                  touched.organization && !!errors.organization
                                }
                                helperText={
                                  touched.organization && errors.organization
                                }
                                label="Target Organization"
                                variant="outlined"
                              />
                            )}
                            renderOption={option => option.title}
                            style={{ width: 300 }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            component={TextField}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            label="First Name"
                            name="firstName"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            component={TextField}
                            error={Boolean(touched.lastName && errors.lastName)}
                            fullWidth
                            helperText={touched.lastName && errors.lastName}
                            label="Last Name"
                            name="lastName"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email Address"
                            name="email"
                            type="email"
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            error={Boolean(
                              touched.addressLine1 && errors.addressLine1
                            )}
                            fullWidth
                            helperText={
                              touched.addressLine1 && errors.addressLine1
                            }
                            label="Address Line 1"
                            name="addressLine1"
                            type="text"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            error={Boolean(
                              touched.addressLine2 && errors.addressLine2
                            )}
                            fullWidth
                            helperText={
                              touched.addressLine2 && errors.addressLine2
                            }
                            label="Address Line 2"
                            name="addressLine2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Field
                            component={TextField}
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            label="City"
                            name="city"
                            type="text"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Field
                            component={TextField}
                            error={Boolean(touched.state && errors.state)}
                            fullWidth
                            helperText={touched.state && errors.state}
                            label="State"
                            name="state"
                            type="text"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            error={Boolean(touched.zip && errors.zip)}
                            fullWidth
                            helperText={touched.zip && errors.zip}
                            label="Zip Code"
                            name="zip"
                            type="text"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box mt={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Enter payment information
                        </Button>
                      </Box>
                    </form>
                  )}
                </>
              )}
            </Formik>
          )}
          {viewState === "CARD" && (
            <Elements stripe={stripePromise}>
              <StripeForm
                addPayment={addPaymentMethod}
                paymentInfo={paymentInfo}
              />
            </Elements>
          )}
          {viewState === "THANKS" && <h2>Thanks for your support!</h2>}
        </CardContent>
      </Card>
    </>
  );
};

export default CCForm;
