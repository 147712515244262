import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Grid, Typography, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import "../css/style.styl";
import { ThemeProvider } from "@material-ui/styles";
import CCForm from "./CCForm";

const styles = {
  container: {
    marginTop: 94,
  },
  contentBox: {
    maxWidth: "calc(1136px - 60px)",
    width: "calc(100% - 60px)",
  },
  title: {
    textAlign: "center",
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8e1515",
    },
    secondary: {
      main: "rgba(255,255,255,0.95)",
    },
  },
});

const Component = ({ classes }) => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        className={classes.container}
        container
        direction="row"
        justify="center"
      >
        <Grid className={classes.contentBox} item>
          <br />
          <Typography variant="h3">Donate</Typography>
          <br />
          <Typography variant="body1">
            Supporting Black non-profits is a key step towards creating a better
            future. Your dollars will help make the world a better place.
          </Typography>
          <br />
          <CCForm />
          <Footer />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default withStyles(styles)(Component);
