import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { TextField, Grid, Box, Typography, Button } from "@material-ui/core";
import StripeInput from "./StripeInput";

const CheckoutForm = ({ paymentInfo, addPayment }) => {
  const stripe = useStripe();
  const elements = useElements();

  console.log(paymentInfo);

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();

    console.log("in submit");

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);
    console.log("card Elemenet: ", cardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      addPayment(paymentMethod);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                },
              }}
              fullWidth
              label="Credit Card Number"
              name="ccnumber"
              required
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                },
              }}
              fullWidth
              label="Expiration Date"
              name="ccexp"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                },
              }}
              fullWidth
              label="CVC"
              name="cvc"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Box>
              <Button
                color="primary"
                disabled={!stripe}
                size="large"
                type="submit"
                variant="contained"
              >
                Pay
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CheckoutForm;
